import {
  Alert,
  Button,
  Container,
  Snackbar,
  Stack,
  TextField,
} from "@mui/material";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Redirect } from "wouter";
import { ReactComponent as Logo } from "../assets/logo.svg";
import LanguageSwitcher from "../components/LanguageSwitcher";
import VersionInfo from "../components/VersionInfo";
import userStore from "../stores/UserStore";
import ErrorScreen from "./ErrorScreen";

const LoginScreen = observer(() => {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loginFailed, setLoginFailed] = useState(false);

  if (userStore.token) {
    return <Redirect href="/building/" />;
  }

  return (
    <ErrorScreen>
      <Container maxWidth="xs" sx={{ marginTop: 5 }}>
        <Snackbar
          open={loginFailed}
          autoHideDuration={10000}
          onClose={() => setLoginFailed(false)}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert onClose={() => setLoginFailed(false)} severity="error">
            {t("Username or password is incorrect!")}
          </Alert>
        </Snackbar>
        <form
          onSubmit={(event) => {
            event.preventDefault();
            userStore.login(email, password).then((success) => {
              if (!success) {
                setLoginFailed(true);
              }
            });
            return false;
          }}
        >
          <Stack spacing={2} marginTop={3}>
            <Logo />
            <TextField
              variant="outlined"
              type="email"
              label={t("Email address")}
              onChange={(event) => setEmail(event.target.value)}
            />
            <TextField
              variant="outlined"
              type="password"
              label={t("Password")}
              onChange={(event) => setPassword(event.target.value)}
            />
            <Button variant="contained" type="submit">
              {t("Login")}
            </Button>
            <LanguageSwitcher />
            <VersionInfo />
          </Stack>
        </form>
      </Container>
    </ErrorScreen>
  );
});

export default LoginScreen;
