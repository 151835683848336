import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextareaAutosize,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { observer } from "mobx-react-lite";
import { nanoid } from "nanoid";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { Light as SyntaxHighlighter } from "react-syntax-highlighter";
import python from "react-syntax-highlighter/dist/esm/languages/hljs/python";
import ocean from "react-syntax-highlighter/dist/esm/styles/hljs/ocean";
import { ILogMessage } from "../services/Log";
import IRule, {
  INotificationAction,
  IScriptAction,
  ISetTraitAction,
  IVaryTraitAction,
  runUnattachedAction,
  TNotificationActionLevel,
} from "../services/Rule";
import coreStore from "../stores/CoreStore";
import traitStore from "../stores/TraitStore";
import LogMessage from "./LogMessage";
import { TraitPopperContent } from "./TraitPopper";
import TranslatedTrait, { translateTrait } from "./TranslatedTrait";

SyntaxHighlighter.registerLanguage("python", python);

export interface IActionCard {
  building: string;
  rule: IRule;
  action:
    | ISetTraitAction
    | IVaryTraitAction
    | IScriptAction
    | INotificationAction;
  onUpdate: (
    action:
      | ISetTraitAction
      | IVaryTraitAction
      | IScriptAction
      | INotificationAction,
  ) => void;
  remove: () => void;
}

function isScriptAction(
  action:
    | ISetTraitAction
    | IVaryTraitAction
    | IScriptAction
    | INotificationAction,
): action is IScriptAction {
  return (action as IScriptAction).script !== undefined;
}

function isNotificationAction(
  action:
    | ISetTraitAction
    | IVaryTraitAction
    | IScriptAction
    | INotificationAction,
): action is INotificationAction {
  return (action as INotificationAction).message !== undefined;
}

const ActionCard: FC<IActionCard> = observer((props: IActionCard) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { building, action, onUpdate, remove } = props;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { delay } = action;
  const { plugin } = coreStore;
  const [actionClass, setActionClass] = useState<
    "SetTraitAction" | "VaryTraitAction" | "ScriptAction" | "NotificationAction"
  >(action.action_class);
  const [selectedTraits, setSelectedTraits] = useState<string[]>(
    isScriptAction(action) || isNotificationAction(action) ? [] : action.traits,
  );
  const traits = traitStore.sortedSimpleTraits({
    building_id: building,
    controllable: true,
  });
  const traitClasses = traits
    .map((x) => x.trait_class)
    .filter((trait_class, index, self) => self.indexOf(trait_class) === index)
    .sort((a, b) =>
      translateTrait(a).toLowerCase() < translateTrait(b).toLowerCase()
        ? -1
        : 1,
    );
  const [traitClass, setTraitClass] = useState<string>(
    (selectedTraits.length > 0 &&
      traitStore.find(selectedTraits[0])?.trait_class) ||
      traitClasses[0],
  );
  const unit =
    traits.find((trait) => trait.trait_class === traitClass)?.unit || "";
  const [tempDelay, setTempDelay] = useState(delay);
  const [script, setScript] = useState(
    isScriptAction(action) ? action.script : "",
  );
  const [scriptHelpExpanded, setScriptHelpExpanded] = useState("");
  const [message, setMessage] = useState(
    isNotificationAction(action) ? action.message : "",
  );
  const handleScriptHelpChange =
    (panel: string) => (_event: unknown, isExpanded: boolean) => {
      setScriptHelpExpanded(isExpanded ? panel : "");
    };
  const [logMessages, setLogMessages] = useState<ILogMessage[]>([]);

  const ScriptActionMethods = [
    {
      title: t("Trait Classes"),
      content: [
        "# Possible trait classes:",
        "# IO:",
        "#   OnOff",
        "#   PowerOnBehavior",
        "#   Dimmer",
        "#   LightTemperature",
        "#   Blind",
        "#   Action",
        "#   Contact",
        "#   FanSpeed",
        "#   ACMode",
        "#   Swing",
        "#   ColorXY",
        "# Sensor:",
        "#   Temperature",
        "#   Humidity",
        "#   BatteryLevel",
        "#   Voltage",
        "#   Current",
        "#   Power",
        "#   Energy",
        "#   Frequency",
        "#   Conductivity",
        "#   Illuminance",
        "#   SoilMoisture",
        "#   PressureBar",
        "#   PressurehPa",
        "#   LinkQuality",
        "#   Occupancy",
        "#   VOC",
        "#   CO2",
        "#   Formaldehyd",
        "#   PM25",
        "#   WindSpeed",
        "#   WindDirection",
        "#   CloudCoverage",
        "# From plugins:",
        ...(plugin.enabled.indexOf("raspautomation.plugin.ac_toshiba")
          ? [
              "#   ToshibaPowerSelection (plugin.ac_toshiba)",
              "#   ToshibaMeritA (plugin.ac_toshiba)",
            ]
          : []),
        ...(plugin.enabled.indexOf("raspautomation.plugin.camera")
          ? ["#   ImageCaptureInterval (plugin.camera)"]
          : []),
      ].join("\n"),
    },
    {
      title: t("Built-ins"),
      content: [
        "# Available built-ins:",
        "None",
        "False",
        "True",
        "abs",
        "all",
        "any",
        "bool",
        "bytes",
        "callable",
        "chr",
        "complex",
        "divmod",
        "float",
        "hash",
        "hex",
        "id",
        "int",
        "isinstance",
        "issubclass",
        "len",
        "oct",
        "ord",
        "pow",
        "range",
        "repr",
        "round",
        "slice",
        "sorted",
        "str",
        "sum",
        "tuple",
        "zip",
      ].join("\n"),
    },
    {
      title: t("Imports"),
      content: [
        "# Available imports:",
        "import math",
        "import requests",
        "import statistics",
      ].join("\n"),
    },
    {
      title: t("Constants"),
      content: [
        "# Available constants:",
        "class DEVICE_TYPE(enum.IntEnum):",
        "  OTHER = 0",
        "  SENSOR = 1",
        "  OUTLET = 2",
        "  LIGHT = 3",
        "  BLIND = 4",
        "  AC_UNIT = 5",
        "  THERMOSTAT = 6",
        "  CAMERA = 7",
      ].join("\n"),
    },
    {
      title: t("Methods"),
      content: [
        "# Available methods (in addition to safe builtins):",
        "def get_value(",
        "  trait_class: str = None,",
        '  aggregation: Literal["mean", "max", "min", "sum", None] = "mean",',
        "  **filters: dict[str, Any]",
        ") -> float:",
        "",
        "def set_value(",
        "  value,",
        "  trait_class: str = None,",
        "  **filters: dict[str, Any]",
        ") -> None:",
        "",
        "def activate_state(",
        "  name: str,  # Name of the State to activate",
        ") -> None:",
        "",
        "def notify(",
        "  message,",
        '  level: Literal["info", "success", "warning", "error"] = "info",',
        ") -> None:",
      ].join("\n"),
    },
    {
      title: t("Variables"),
      content: [
        "# Available variables:",
        "now: Arrow = arrow.now()  # Current time in Buildings local time",
        "utcnow: Arrow = arrow.utcnow()  # Current time in UTC",
        "rule: dict = RuleSerializer(rule).data  # The triggering Rule as defined by API V2",
        "active_state: str | None = None  # Name of the currently active State in lower case",
        "trigger: dict = TriggerPolymorphicSerializer(trigger).data  # The Trigger as defined by API V2",
        "trait: dict | None = TraitPolymorphicSerializer(trait).data  # The triggering Trait as defined by API V2",
        "action: dict | None = None  # The triggering action, passed as dictionary from source, such as Zigbee2MQTT",
        "previous_value: any | None = None  # Previous value, only set if Trigger was Trait value change",
      ].join("\n"),
    },
  ];

  if (plugin.enabled.indexOf("raspautomation.plugin.nordpool") > -1) {
    ScriptActionMethods.push({
      title: t("Spot-prices"),
      content: [
        "# Todays hourly spot prices, sorted by hour (ascending)",
        "spot_prices: list[float] = []",
        "# Todays hourly spot prices, sorted by price (ascending)",
        "sorted_spot_prices: list[float] = []",
        "# Spot price for current hour",
        "current_spot_price: float = float('inf')",
        "# Spot prices for upcoming hours",
        "next_spot_prices: list[float] = []",
      ].join("\n"),
    });
  }

  return (
    <Card>
      <CardContent>
        <Stack spacing={2} marginBottom={-1}>
          <FormControl fullWidth>
            <InputLabel>{t("Action class")}</InputLabel>
            <Select
              variant="outlined"
              label={t("Action class")}
              value={actionClass}
              onChange={(event) => {
                const x = event.target.value as string;
                if (x === "SetTraitAction") {
                  setActionClass(x);
                  onUpdate({
                    uuid: action.uuid,
                    action_class: "SetTraitAction",
                    delay: action.delay,
                    traits: selectedTraits,
                    value: (action as ISetTraitAction).value,
                    filter: 0,
                    filter_value: 0,
                  });
                } else if (x === "VaryTraitAction") {
                  setActionClass(x);
                  onUpdate({
                    uuid: action.uuid,
                    action_class: "VaryTraitAction",
                    delay: action.delay,
                    traits: selectedTraits,
                    value: (action as IVaryTraitAction).value,
                  });
                } else if (x === "ScriptAction") {
                  setActionClass(x);
                  onUpdate({
                    uuid: action.uuid,
                    action_class: "ScriptAction",
                    delay: delay,
                    script: script,
                  });
                } else if (x === "NotificationAction") {
                  setActionClass(x);
                  onUpdate({
                    uuid: action.uuid,
                    action_class: "NotificationAction",
                    delay: delay,
                    message: "",
                    level: "info",
                  });
                }
              }}
            >
              <MenuItem value="SetTraitAction">{t("SetTraitAction")}</MenuItem>
              <MenuItem value="VaryTraitAction">
                {t("VaryTraitAction")}
              </MenuItem>
              <MenuItem value="NotificationAction">
                {t("NotificationAction")}
              </MenuItem>
              <MenuItem value="ScriptAction">{t("ScriptAction")}</MenuItem>
            </Select>
          </FormControl>
          <TextField
            variant="outlined"
            type="number"
            label={t("Delay in seconds")}
            value={tempDelay.toString()}
            onChange={(event) => {
              setTempDelay(parseFloat(event.target.value));
            }}
            onBlur={() => {
              if (Number.isFinite(tempDelay)) {
                onUpdate({
                  ...action,
                  delay: tempDelay,
                });
              }
            }}
          />
          {!isScriptAction(action) && !isNotificationAction(action) && (
            <>
              <FormControl fullWidth>
                <InputLabel>{t("Class")}</InputLabel>
                <Select
                  variant="outlined"
                  label={t("Class")}
                  value={traitClass}
                  renderValue={(x) => <TranslatedTrait traitClass={x} />}
                  onChange={(event) => {
                    if (event.target.value !== traitClass) {
                      setSelectedTraits([]);
                    }
                    setTraitClass(event.target.value);
                  }}
                >
                  {traitClasses.map((traitclass) => (
                    <MenuItem key={traitclass} value={traitclass}>
                      <TranslatedTrait traitClass={traitclass} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth>
                <InputLabel>{t("Devices")}</InputLabel>
                <Select
                  label={t("Devices")}
                  multiple
                  value={selectedTraits}
                  renderValue={(selected) =>
                    selected
                      .map((item) => traitStore.getTraitText(item))
                      .join(", ")
                  }
                  onChange={(event) => {
                    const selected =
                      typeof event.target.value === "string"
                        ? (event.target.value as string).split(",")
                        : event.target.value;
                    setSelectedTraits(selected);
                  }}
                  onClose={() => {
                    onUpdate({
                      ...action,
                      traits: selectedTraits,
                    });
                  }}
                >
                  {traits
                    .filter((trait) => trait.trait_class === traitClass)
                    .map((trait) => (
                      <MenuItem key={trait.uuid} value={trait.uuid}>
                        <Checkbox
                          color="secondary"
                          checked={selectedTraits.indexOf(trait.uuid) > -1}
                        />
                        {traitStore.getTraitText(trait.uuid)}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              {selectedTraits.length > 0 && (
                <Stack
                  direction="column"
                  alignItems="center"
                  sx={{
                    backgroundColor: theme.palette.background.paper,
                    backgroundImage:
                      "linear-gradient(rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4))",
                    marginBottom: "-3px",
                    padding: "15px",
                    borderRadius: "4px",
                  }}
                >
                  <TraitPopperContent
                    name={<TranslatedTrait traitClass={traitClass} />}
                    traitClass={traitClass}
                    value={action.value}
                    unit={unit}
                    onSet={(value: number | string) => {
                      if (Number.isFinite(value)) {
                        onUpdate({
                          ...action,
                          value: value as number,
                        });
                      }
                    }}
                  />
                </Stack>
              )}
            </>
          )}
          {isNotificationAction(action) && (
            <>
              <FormControl fullWidth>
                <InputLabel>{t("Level")}</InputLabel>
                <Select
                  variant="outlined"
                  label={t("Level")}
                  value={action.level}
                  onChange={(event) => {
                    onUpdate({
                      ...action,
                      level: event.target.value as TNotificationActionLevel,
                    });
                  }}
                >
                  <MenuItem value="info">{t("info")}</MenuItem>
                  <MenuItem value="success">{t("success")}</MenuItem>
                  <MenuItem value="warning">{t("warning")}</MenuItem>
                  <MenuItem value="error">{t("error")}</MenuItem>
                </Select>
              </FormControl>
              <TextField
                variant="outlined"
                type="text"
                label={t("Message")}
                value={message}
                onChange={(event) => {
                  setMessage(event.target.value);
                }}
                onBlur={() => {
                  onUpdate({
                    ...action,
                    message: message,
                  });
                }}
              />
              <Typography>
                {t("Available variables for TraitValueTrigger")}:
              </Typography>
              <pre>
                {`building_name
room_name
device_name
nickname
value
limit`}
              </pre>
            </>
          )}
          {isScriptAction(action) && (
            <>
              {ScriptActionMethods.map((documentation, index) => (
                <Accordion
                  key={`ScriptActionDocumentation-${index}`}
                  expanded={scriptHelpExpanded === documentation.title}
                  onChange={handleScriptHelpChange(documentation.title)}
                >
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>{documentation.title}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <SyntaxHighlighter language="python" style={ocean}>
                      {documentation.content}
                    </SyntaxHighlighter>
                  </AccordionDetails>
                </Accordion>
              ))}

              <Typography>{t("Script")}</Typography>
              <TextareaAutosize
                minRows={3}
                placeholder={t("Script")}
                value={script}
                onChange={(event) => {
                  setScript(event.target.value);
                }}
                onBlur={() => {
                  onUpdate({
                    ...action,
                    script: script,
                  } as IScriptAction);
                }}
                spellCheck={false}
              />

              <Typography>{t("Syntax highlighted script")}</Typography>
              <SyntaxHighlighter language="python" style={ocean}>
                {script}
              </SyntaxHighlighter>
            </>
          )}
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          {logMessages.map((message) => (
            <LogMessage key={nanoid()} {...message} />
          ))}
        </Stack>
      </CardContent>
      <CardActions>
        <Button
          variant="contained"
          color="secondary"
          startIcon={<DeleteForeverIcon />}
          onClick={() => remove()}
        >
          {t("Delete")}
        </Button>
        <Button
          variant="contained"
          color="secondary"
          startIcon={<PlayArrowIcon />}
          onClick={() =>
            runUnattachedAction(building, action).then((messages) =>
              setLogMessages(messages),
            )
          }
        >
          {t("Run")}
        </Button>
      </CardActions>
    </Card>
  );
});

export default ActionCard;
