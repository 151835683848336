import { createTheme } from "@mui/material";

const mainTheme = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        body {
          margin-bottom: 20vh;
        }
      `,
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          paddingRight: "30px",
          "&.Mui-selected": {
            backgroundColor: "#182a19",
          },
        },
      },
    },
    MuiBottomNavigationAction: {
      styleOverrides: {
        root: {
          color: "#4f6350",
          "&.Mui-selected": {
            color: "white",
          },
        },
      },
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          justifyContent: "flex-end",
        },
      },
    },
    // MuiMenuItem: {
    //   styleOverrides: {
    //     root: {
    //       '&.Mui-selected': {
    //         backgroundColor: '#41257b',
    //       },
    //     },
    //   },
    // },
    MuiChip: {
      styleOverrides: {
        root: {
          paddingTop: 20,
          paddingBottom: 20,
          marginRight: 5,
          marginBottom: 5,
          "& .MuiChip-label": {
            flexGrow: 1,
          },
          "& .MuiChip-deleteIcon": {
            marginRight: 10,
          },
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        autoComplete: "off",
      },
    },
    MuiAlert: {
      styleOverrides: {
        message: {
          width: "100%",
          paddingRight: 8,
        },
      },
    },
  },
  palette: {
    mode: "dark",
    primary: {
      light: "#4f6350",
      main: "#233d25",
      dark: "#182a19",
    },
    secondary: {
      light: "#2d1956",
      main: "#41257b",
      dark: "#675095",
    },
    background: {
      default: "#182a19",
      paper: "#233d25",
    },
  },
});

export default mainTheme;
