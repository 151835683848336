import IState from "../services/State";
import BuildingChildPagedStore, {
  IBuildingChildFilter,
} from "./BuildingChildPagedStore";

class StateStore extends BuildingChildPagedStore<IState, IBuildingChildFilter> {
  constructor() {
    super("state");
    this.persist();
  }
}

const stateStore = new StateStore();

export default stateStore;
