import { DropResult } from "@hello-pangea/dnd";
import EditIcon from "@mui/icons-material/Edit";
import { Button } from "@mui/material";
import { observer } from "mobx-react-lite";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "wouter";
import deviceStore from "../stores/DeviceStore";
import roomStore from "../stores/RoomStore";
import traitStore from "../stores/TraitStore";
import deviceUIStore from "../stores/UI/DeviceUIStore";
import TraitCard from "./TraitCard";
import { ITraitChipHide } from "./TraitChip";

export interface IDeviceUI {
  uuid: string;
  name: string;
  hideEditButton?: boolean;
}

export interface IDeviceType {
  type: string | null;
}

export const DeviceType: FC<IDeviceType> = (props: IDeviceType) => {
  const { t } = useTranslation();

  switch (props.type) {
    case null:
    case "null":
      return <>{t("(not set, trying automatically)")}</>;
    case "":
    case "empty":
      return <>{t("(undefined)")}</>;
    case "other":
      return <>{t("Other")}</>;
    case "sensor":
      return <>{t("Sensor")}</>;
    case "outlet":
      return <>{t("Outlet")}</>;
    case "light":
      return <>{t("Light")}</>;
    case "blind":
      return <>{t("Blind")}</>;
    case "ac_unit":
      return <>{t("AC unit")}</>;
    case "thermostat":
      return <>{t("Thermostat")}</>;
    case "camera":
      return <>{t("Camera")}</>;
    default:
      return <>{t("(unknown)")}</>;
  }
};

const Device: FC<IDeviceUI> = observer((props: IDeviceUI) => {
  const { t } = useTranslation();
  const [, setLocation] = useLocation();
  const device = deviceStore.find(props.uuid);
  const { hiddenTraits, traitOrder } = deviceUIStore.find(props.uuid);
  const roomName =
    device && device.room_id ? roomStore.find(device.room_id)?.name : undefined;
  const traits = traitStore.filter({ device_id: props.uuid, disabled: false });
  const traitGroups = traits
    .sort((a, b) =>
      traitOrder.indexOf(a.uuid) < traitOrder.indexOf(b.uuid) ? -1 : 1,
    )
    .map((trait) => ({
      id: trait.uuid,
      name: trait.nickname || undefined,
      traits: [trait],
    }));

  const onHide = (instance: ITraitChipHide) =>
    deviceUIStore.hideTrait(props.uuid, instance.uuid as string);
  const onShow = (instance: ITraitChipHide) =>
    deviceUIStore.showTrait(props.uuid, instance.uuid as string);
  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    const newOrder = [...traitGroups.map((group) => group.id)];
    const [moved] = newOrder.splice(result.source.index, 1);
    newOrder.splice(result.destination.index, 0, moved);
    deviceUIStore.setTraitOrder(props.uuid, newOrder);
  };

  if (!device) {
    return null;
  }

  return (
    <TraitCard
      id={props.hideEditButton ? `editable-${device.uuid}` : `${device.uuid}`}
      title={device.name}
      subtitle={roomName}
      traitGroups={traitGroups}
      hiddenTraits={hiddenTraits}
      onHide={onHide}
      onShow={onShow}
      onDragEnd={onDragEnd}
      actions={
        props.hideEditButton
          ? undefined
          : [
              <Button
                key={`edit-${device.uuid}`}
                variant="contained"
                color="secondary"
                startIcon={<EditIcon />}
                onClick={() =>
                  setLocation(
                    `/building/${device.building_id}/device/${device.uuid}/`,
                  )
                }
              >
                {t("Edit")}
              </Button>,
            ]
      }
    />
  );
});

export default Device;
