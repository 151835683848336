import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Menu, MenuItem } from "@mui/material";
import { observer } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next";
import { activateState } from "../services/State";
import stateStore from "../stores/StateStore";
import stateUIStore from "../stores/UI/StateUIStore";
import SmallIconButton from "./SmallIconButton";

interface IStateDropDownProps {
  building_id: string;
}

const StateDropDown = observer((props: IStateDropDownProps) => {
  const { t } = useTranslation();
  const { building_id } = props;
  const states = stateStore
    .filter({ building_id: building_id })
    .sort((a, b) =>
      stateUIStore.findIndex(a.uuid) < stateUIStore.findIndex(b.uuid) ? -1 : 1,
    );
  const activeState = states.find((state) => state.active);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  if (states.length === 0) {
    return null;
  }

  return (
    <>
      <SmallIconButton
        variant="contained"
        text={activeState ? activeState.name : t("state")}
        // sx={{ paddingY: 0, height: 40 }}
        endIcon={<ArrowDropDownIcon />}
        onClick={(event) => setAnchorEl(event.currentTarget)}
      />
      <Menu
        sx={{ zIndex: 10000 }}
        open={open}
        onClose={() => setAnchorEl(null)}
        anchorEl={anchorEl}
      >
        {states.map((state) => (
          <MenuItem
            key={state.uuid}
            selected={state.active}
            onClick={() => activateState(state).then(() => setAnchorEl(null))}
          >
            {state.name}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
});

export default StateDropDown;
