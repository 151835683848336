import IRoom from "../services/Room";
import BuildingChildPagedStore, {
  IBuildingChildFilter,
} from "./BuildingChildPagedStore";

export type IRoomFilter = IBuildingChildFilter;

class RoomStore extends BuildingChildPagedStore<IRoom, IRoomFilter> {
  constructor() {
    super("room");
    this.persist();
  }
}

const roomStore = new RoomStore();
export default roomStore;
