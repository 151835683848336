import { Button, ButtonProps } from "@mui/material";
import React, { FC } from "react";
import { isSmall } from "../utils";

export interface ISmallIconButton {
  variant?: ButtonProps["variant"];
  color?: ButtonProps["color"];
  startIcon?: ButtonProps["startIcon"];
  endIcon?: ButtonProps["endIcon"];
  text: string | React.ReactNode;
  onClick: ButtonProps["onClick"];
}

const SmallIconButton: FC<ISmallIconButton> = (props: ISmallIconButton) => {
  const smallDevice = isSmall();
  const { variant, color, startIcon, endIcon, text, onClick } = props;

  if (smallDevice) {
    return (
      <Button
        variant={variant}
        color={color}
        startIcon={startIcon}
        endIcon={endIcon}
        onClick={onClick}
        sx={{
          paddingX: 0,
          minWidth: 40,
          "& .MuiButton-startIcon": {
            marginLeft: 0,
            marginRight: 0,
          },
        }}
      />
    );
  }

  return (
    <Button
      variant={variant}
      color={color}
      startIcon={startIcon}
      endIcon={endIcon}
      onClick={onClick}
      sx={{
        marginLeft: 2,
      }}
    >
      {text}
    </Button>
  );
};

export default SmallIconButton;
