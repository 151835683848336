import { Grid } from "@mui/material";
import { observer } from "mobx-react-lite";
import { FC } from "react";
import groupStore from "../stores/GroupStore";
import groupUIStore from "../stores/UI/GroupUIStore";
import Group from "./Group";

export interface IGroupList {
  building_id: string;
  search: string;
}

const GroupList: FC<IGroupList> = observer((props: IGroupList) => {
  const { building_id, search } = props;
  const groups = groupStore
    .filter({ building_id: building_id })
    .filter((building) => building.name.toLowerCase().includes(search))
    .sort((a, b) =>
      groupUIStore.findIndex(a.uuid) < groupUIStore.findIndex(b.uuid) ? -1 : 1,
    );

  return (
    <>
      <Grid container spacing={2} justifyContent="center">
        {groups.map((group) => (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <Group key={group.uuid} {...group} />
        ))}
      </Grid>
    </>
  );
});

export default GroupList;
