import { Grid } from "@mui/material";
import { observer } from "mobx-react-lite";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import roomStore from "../stores/RoomStore";
import traitStore from "../stores/TraitStore";
import roomUIStore from "../stores/UI/RoomUIStore";
import Room from "./Room";

export interface IRoomList {
  building_id: string;
  search: string;
}

const RoomList: FC<IRoomList> = observer((props: IRoomList) => {
  const { t } = useTranslation();
  const { building_id, search } = props;
  const rooms = roomStore
    .filter({ building_id: building_id })
    .filter((room) => room.name.toLowerCase().includes(search))
    .sort((a, b) =>
      roomUIStore.findIndex(a.uuid) < roomUIStore.findIndex(b.uuid) ? -1 : 1,
    );
  const traitsWithoutRoom = traitStore.filter({
    building_id: props.building_id,
    room_id: null,
  });

  return (
    <Grid container spacing={2} justifyContent="center">
      {rooms.map((room) => (
        <Room
          key={room.uuid}
          building_id={props.building_id}
          uuid={room.uuid}
          name={room.name}
        />
      ))}
      <Room
        building_id={props.building_id}
        uuid={`${props.building_id}-other`}
        name={t("Other spaces")}
        traits={traitsWithoutRoom}
      />
    </Grid>
  );
});

export default RoomList;
