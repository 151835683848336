import IRule from "../services/Rule";
import BuildingChildPagedStore, {
  IBuildingChildFilter,
} from "./BuildingChildPagedStore";

export type IRuleFilter = IBuildingChildFilter;

class RuleStore extends BuildingChildPagedStore<IRule, IRuleFilter> {
  constructor() {
    super("rule");
    this.persist();
  }
}

const ruleStore = new RuleStore();
export default ruleStore;
