import { Grid } from "@mui/material";
import { observer } from "mobx-react-lite";
import { FC } from "react";
import deviceStore from "../stores/DeviceStore";
import deviceUIStore from "../stores/UI/DeviceUIStore";
import Device from "./Device";

export interface IDeviceList {
  building_id: string;
  search: string;
}

const DeviceList: FC<IDeviceList> = observer((props: IDeviceList) => {
  const { building_id, search } = props;
  const devices = deviceStore
    .filter({ building_id: building_id, disabled: false })
    .filter((device) => device.name.toLowerCase().includes(search))
    .sort((a, b) =>
      deviceUIStore.findIndex(a.uuid) < deviceUIStore.findIndex(b.uuid)
        ? -1
        : 1,
    );

  return (
    <Grid container spacing={2} justifyContent="center">
      {devices.map((device) => (
        <Device
          key={device.uuid}
          uuid={device.uuid}
          name={device.name ? device.name : ""}
        />
      ))}
    </Grid>
  );
});

export default DeviceList;
