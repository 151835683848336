export interface FirebaseMessaging {
  token: string;
}

export interface EnvironmentConfig {
  backend: string;
  mqtt: string;
  trait_error_cutoff: number;
  refresh_cutoff: number;
  chartRefreshInterval: number; // in minutes
  version: string;

  firebase_messaging: FirebaseMessaging;
}

const getEnvironmentConfig = (): EnvironmentConfig => ({
  backend: "https://api.ra.hirvi.ovh/api/v2",
  mqtt: "wss://mqtt.ra.hirvi.ovh/mqtt",
  // backend: "https://api.ra.dev.hirvi.ovh/api/v2",
  // mqtt: "wss://mqtt.ra.dev.hirvi.ovh/mqtt",
  trait_error_cutoff: 6 * 3600,
  refresh_cutoff: 7,
  chartRefreshInterval: 5,
  version:
    process.env.NODE_ENV === "development"
      ? "development"
      : process.env.REACT_APP_CI_COMMIT_TAG || "production",
  firebase_messaging: {
    token:
      "BE66W4LOELbyOuQM4B27fgLq3-bQfuzp5uG7y-nmbAWCd-WxgbTTMeGa6nfaaQr_13G2TYmEpkyr3IlDgD18w-w",
  },
});

export default getEnvironmentConfig;
