import { DateTime } from "luxon";
import { makeObservable, observable, runInAction } from "mobx";
import getEnvironmentConfig from "../environment/config";
import { IPlugin, getPlugins } from "../services/Core";
import { IPersistedStore } from "./PersistedStore";
import { persist } from "./utils";

interface ILastRefresh {
  building_id: string;
  lastRefresh: string;
}

interface ICoreStore extends IPersistedStore {
  previousBuilding: string | null;
  plugin: IPlugin;
  updateAvailable: boolean;
  lastRefreshes: ILastRefresh[];
  setPreviousBuilding: (building_id: string) => void;
  update: () => void;
  setUpdateAvailable: (value: boolean) => void;
  isPluginEnabled: (name: string) => boolean;
  shouldRefresh: (building_id: string) => boolean;
  updateLastRefresh: (building_id: string) => void;
}

class CoreStore implements ICoreStore {
  isHydrated = false;
  previousBuilding: string | null = null;
  plugin: IPlugin = {
    enabled: [],
    available: [],
  };
  updateAvailable = false;
  lastRefreshes: ILastRefresh[] = [];

  constructor() {
    makeObservable(this, {
      previousBuilding: observable,
      plugin: observable,
      updateAvailable: observable,
      isHydrated: observable,
      lastRefreshes: observable,
    });

    persist(
      this,
      "core",
      ["previousBuilding", "plugin", "lastRefreshes"],
      undefined,
      1,
    );

    window.navigator.serviceWorker.getRegistration().then((registration) => {
      setInterval(() => {
        registration?.update();
      }, 900000);

      registration?.addEventListener("updatefound", () => {
        const newWorker = registration.installing;
        newWorker?.addEventListener("statechange", () => {
          if (newWorker.state === "installed") {
            this.setUpdateAvailable(true);
          } else if (newWorker.state === "activated") {
            window.location.reload();
          }
        });
      });
    });
  }

  setPreviousBuilding = (building_id: string | null) => {
    runInAction(() => {
      this.previousBuilding = building_id;
    });
  };

  update = async () => {
    const plugin = await getPlugins();
    runInAction(() => {
      this.plugin = plugin;
    });
  };

  setUpdateAvailable = (value: boolean) => {
    runInAction(() => {
      this.updateAvailable = value;
    });
  };

  isPluginEnabled = (name: string) =>
    this.plugin.enabled.indexOf(`raspautomation.plugin.${name}`) !== -1;

  shouldRefresh = (building_id: string) => {
    const now = DateTime.now();
    const instance = this.lastRefreshes.find(
      (x) => x.building_id === building_id,
    );
    if (!instance) {
      return true;
    }
    return (
      now.diff(DateTime.fromISO(instance.lastRefresh), "days").as("days") >
      getEnvironmentConfig().refresh_cutoff
    );
  };

  updateLastRefresh = (building_id: string) => {
    const now = DateTime.now().toISO();
    runInAction(() => {
      const instance = this.lastRefreshes.find(
        (x) => x.building_id === building_id,
      );
      if (instance) {
        this.lastRefreshes = this.lastRefreshes.map((x) =>
          x.building_id === building_id ? { ...x, lastRefresh: now } : x,
        );
      } else {
        this.lastRefreshes = [
          ...this.lastRefreshes,
          {
            building_id: building_id,
            lastRefresh: now,
          },
        ];
      }
    });
  };
}

const coreStore = new CoreStore();

export default coreStore;
