import {
  Box,
  Card,
  CardContent,
  Checkbox,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useRoute } from "wouter";

import SaveIcon from "@mui/icons-material/Save";
import { DateTime } from "luxon";
import { nanoid } from "nanoid";
import { useTranslation } from "react-i18next";
import AppBar from "../components/AppBar";
import Chart from "../components/Chart";
import DeleteFab from "../components/DeleteFab";
import SmallIconButton from "../components/SmallIconButton";
import TranslatedTrait from "../components/TranslatedTrait";
import { TChartAggregate, TChartWindow } from "../services/Trait";
import traitStore from "../stores/TraitStore";
import chartUIStore, {
  IChart,
  ISankeyChart,
  TChartType,
  TChartTypes,
  isSankey,
} from "../stores/UI/ChartUIStore";
import ErrorScreen from "./ErrorScreen";

interface IChartEditProps {
  building: string;
  id: string;
  chart: TChartTypes;
  traitClasses: string[];
  updateChart: (options: Partial<TChartTypes>) => void;
}

const ChartEdit = (props: IChartEditProps) => {
  const { t } = useTranslation();
  const { building, chart, traitClasses, updateChart } = props;
  const traits = traitStore
    .sortedSimpleTraits({ building_id: building })
    .filter((trait) => trait.trait_class === chart.traitClass);

  return (
    <ErrorScreen>
      <FormControl fullWidth>
        <InputLabel>{t("Aggregate")}</InputLabel>
        <Select
          variant="outlined"
          label={t("Aggregate")}
          value={chart.aggregate}
          onChange={(event) => {
            const { value } = event.target;
            updateChart({ aggregate: value as TChartAggregate });
          }}
        >
          <MenuItem value="mean">{t("Mean")}</MenuItem>
          <MenuItem value="min">{t("Minimum")}</MenuItem>
          <MenuItem value="max">{t("Maximum")}</MenuItem>
          <MenuItem value="diff">{t("Difference")}</MenuItem>
          <MenuItem value="non_negative_diff">
            {t("Non-negative difference")}
          </MenuItem>
          <MenuItem value="">{t("(none)")}</MenuItem>
        </Select>
      </FormControl>
      <FormControl fullWidth>
        <InputLabel>{t("Time period")}</InputLabel>
        <Select
          variant="outlined"
          label={t("Time period")}
          value={chart.window}
          onChange={(event) => {
            const { value } = event.target;
            updateChart({ window: value as TChartWindow });
          }}
        >
          <MenuItem value="1m">1m</MenuItem>
          <MenuItem value="5m">5m</MenuItem>
          <MenuItem value="15m">15m</MenuItem>
          <MenuItem value="30m">30m</MenuItem>
          <MenuItem value="1h">1h</MenuItem>
          <MenuItem value="3h">3h</MenuItem>
          <MenuItem value="6h">6h</MenuItem>
          <MenuItem value="12h">12h</MenuItem>
          <MenuItem value="24h">24h</MenuItem>
          <MenuItem value="">{t("(none)")}</MenuItem>
        </Select>
      </FormControl>
      <FormControl fullWidth>
        <InputLabel>{t("Class")}</InputLabel>
        <Select
          variant="outlined"
          label={t("Class")}
          value={chart.traitClass}
          renderValue={(value) => <TranslatedTrait traitClass={value} />}
          onChange={(event) => {
            const { value } = event.target;
            if (value !== chart.traitClass) {
              updateChart({
                traitClass: value,
                traits: [],
              });
            } else {
              updateChart({
                traitClass: value,
              });
            }
          }}
        >
          {traitClasses.map((traitclass) => (
            <MenuItem key={traitclass} value={traitclass}>
              <TranslatedTrait traitClass={traitclass} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl fullWidth>
        <InputLabel>{t("Devices")}</InputLabel>
        <Select
          label={t("Devices")}
          multiple
          value={chart.traits}
          renderValue={(selected) =>
            selected.map((item) => traitStore.getTraitText(item)).join(", ")
          }
          onChange={(event) => {
            const { value } = event.target;
            updateChart({
              traits:
                typeof value === "string"
                  ? (value as string).split(",")
                  : value,
            });
          }}
        >
          {traits.map((trait) => (
            <MenuItem key={trait.uuid} value={trait.uuid}>
              <Checkbox
                color="secondary"
                checked={chart.traits.indexOf(trait.uuid) > -1}
              />
              {traitStore.getTraitText(trait.uuid)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </ErrorScreen>
  );
};

const GanttChartEdit = (props: IChartEditProps) => {
  const { t } = useTranslation();
  const { building, chart, traitClasses, updateChart } = props;
  const traits = traitStore
    .sortedSimpleTraits({ building_id: building })
    .filter((trait) => trait.trait_class === chart.traitClass);

  return (
    <ErrorScreen>
      <FormControl fullWidth>
        <InputLabel>{t("Class")}</InputLabel>
        <Select
          variant="outlined"
          label={t("Class")}
          value={chart.traitClass}
          renderValue={(value) => <TranslatedTrait traitClass={value} />}
          onChange={(event) => {
            const { value } = event.target;
            if (value !== chart.traitClass) {
              updateChart({
                traitClass: value,
                traits: [],
              });
            } else {
              updateChart({
                traitClass: value,
              });
            }
          }}
        >
          {traitClasses.map((traitclass) => (
            <MenuItem key={traitclass} value={traitclass}>
              <TranslatedTrait traitClass={traitclass} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl fullWidth>
        <InputLabel>{t("Devices")}</InputLabel>
        <Select
          label={t("Devices")}
          multiple
          value={chart.traits}
          renderValue={(selected) =>
            selected.map((item) => traitStore.getTraitText(item)).join(", ")
          }
          onChange={(event) => {
            const { value } = event.target;
            updateChart({
              traits:
                typeof value === "string"
                  ? (value as string).split(",")
                  : value,
            });
          }}
        >
          {traits.map((trait) => (
            <MenuItem key={trait.uuid} value={trait.uuid}>
              <Checkbox
                color="secondary"
                checked={chart.traits.indexOf(trait.uuid) > -1}
              />
              {traitStore.getTraitText(trait.uuid)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </ErrorScreen>
  );
};

const SankeyChartEdit = (props: IChartEditProps) => {
  const { t } = useTranslation();
  const { building, chart, updateChart } = props;
  const traits = traitStore
    .sortedSimpleTraits({ building_id: building })
    .filter((trait) => trait.trait_class === chart.traitClass);

  if (!isSankey(chart)) {
    return null;
  }

  return (
    <ErrorScreen>
      <FormControl fullWidth>
        <InputLabel>{t("Grid")}</InputLabel>
        <Select
          label={t("Grid")}
          value={chart.grid}
          renderValue={(selected) => traitStore.getTraitText(selected)}
          onChange={(event) => {
            const { value } = event.target;
            updateChart({
              grid: value,
            });
          }}
        >
          {traits.map((trait) => (
            <MenuItem key={trait.uuid} value={trait.uuid}>
              {traitStore.getTraitText(trait.uuid)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl fullWidth>
        <InputLabel>{t("Production")}</InputLabel>
        <Select
          label={t("Production")}
          multiple
          value={chart.production}
          renderValue={(selected) =>
            selected.map((item) => traitStore.getTraitText(item)).join(", ")
          }
          onChange={(event) => {
            const { value } = event.target;
            updateChart({
              production:
                typeof value === "string"
                  ? (value as string).split(",")
                  : value,
            });
          }}
        >
          {traits.map((trait) => (
            <MenuItem key={trait.uuid} value={trait.uuid}>
              <Checkbox
                color="secondary"
                checked={chart.production.indexOf(trait.uuid) > -1}
              />
              {traitStore.getTraitText(trait.uuid)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl fullWidth>
        <InputLabel>{t("Consumption")}</InputLabel>
        <Select
          label={t("Consumption")}
          multiple
          value={chart.traits}
          renderValue={(selected) =>
            selected.map((item) => traitStore.getTraitText(item)).join(", ")
          }
          onChange={(event) => {
            const { value } = event.target;
            updateChart({
              traits:
                typeof value === "string"
                  ? (value as string).split(",")
                  : value,
            });
          }}
        >
          {traits.map((trait) => (
            <MenuItem key={trait.uuid} value={trait.uuid}>
              <Checkbox
                color="secondary"
                checked={chart.traits.indexOf(trait.uuid) > -1}
              />
              {traitStore.getTraitText(trait.uuid)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl fullWidth>
        <InputLabel>{t("Export")}</InputLabel>
        <Select
          label={t("Export")}
          value={chart.export}
          renderValue={(selected) => traitStore.getTraitText(selected)}
          onChange={(event) => {
            const { value } = event.target;
            updateChart({
              export: value,
            });
          }}
        >
          {traits.map((trait) => (
            <MenuItem key={trait.uuid} value={trait.uuid}>
              {traitStore.getTraitText(trait.uuid)}
            </MenuItem>
          ))}
          <MenuItem value={""}>{t("(none)")}</MenuItem>
        </Select>
      </FormControl>
    </ErrorScreen>
  );
};

const ChartEditScreen = (): JSX.Element => {
  const { t } = useTranslation();
  const [, params] = useRoute("/building/:building_id/chart/:id?/");
  const building: string =
    params && params.building_id ? params.building_id : "";
  const id: string = params && params.id ? params.id : "";
  const traits = traitStore.sortedSimpleTraits({ building_id: building });
  const [chart, setChart] = useState<TChartTypes>(
    chartUIStore.find(id) ||
      ({
        id: nanoid(),
        building_id: building,
        name: "",
        type: "line",
        aggregate: "mean",
        window: "5m",
        traitClass: "Temperature",
        traits: [],
      } as IChart),
  );
  const getTraitClasses = () =>
    traits
      .map((trait) => trait.trait_class)
      .filter(
        (trait_class, index, self) => self.indexOf(trait_class) === index,
      );
  const [traitClasses, setTraitClasses] = useState<string[]>(getTraitClasses());
  const [isValid, setIsValid] = useState(false);

  const updateChart = (options: Partial<TChartTypes>) => {
    if (chart.type !== options.type) {
      if (options.type === "sankey") {
        options = {
          ...options,
          traitClass: "Energy",
          window: "1h",
          aggregate: "non_negative_diff",
          grid: "",
          production: [],
          export: "",
        } as ISankeyChart;
        setTraitClasses(["Energy"]);
      } else if (options.type === "gantt") {
        options = {
          ...options,
          traitClass: "OnOff",
          aggregate: "",
          window: "",
        };
        setTraitClasses(["OnOff", "Contact", "Occupancy", "WaterLeak"]);
      } else {
        setTraitClasses(getTraitClasses());
      }
    }
    setChart({
      ...chart,
      ...options,
    });
  };

  useEffect(() => {
    if (isSankey(chart)) {
      return setIsValid(true);
    }
    if (chart.traits.length < 1) {
      return setIsValid(false);
    }
    return setIsValid(true);
  }, [chart, chart.traits]);

  const onSave = () => {
    chartUIStore.update({
      ...chart,
      id: chart.id,
      building_id: building,
    });
    window.history.back();
  };

  return (
    <Box>
      <AppBar
        title={chart.name || t("Edit Chart")}
        withBack
        right={
          isValid && (
            <SmallIconButton
              variant="contained"
              color="secondary"
              text={t("Save")}
              startIcon={<SaveIcon />}
              onClick={onSave}
            />
          )
        }
      />
      <ErrorScreen>
        <Container maxWidth="xs" sx={{ marginTop: 2 }}>
          <Card>
            <CardContent>
              <Stack spacing={2} marginBottom={-1}>
                <TextField
                  variant="outlined"
                  type="text"
                  label={t("Name")}
                  value={chart.name}
                  onChange={(event) =>
                    updateChart({ name: event.target.value })
                  }
                />
                <FormControl fullWidth>
                  <InputLabel>{t("Chart type")}</InputLabel>
                  <Select
                    variant="outlined"
                    label={t("Chart type")}
                    value={chart.type}
                    onChange={(event) => {
                      const { value } = event.target;
                      updateChart({ type: value as TChartType });
                    }}
                  >
                    <MenuItem value="line">{t("Line")}</MenuItem>
                    <MenuItem value="column">{t("Column")}</MenuItem>
                    <MenuItem value="gantt">{t("Gantt")}</MenuItem>
                    <MenuItem value="sankey">{t("Sankey")}</MenuItem>
                  </Select>
                </FormControl>
                {chart.type === "gantt" ? (
                  <GanttChartEdit
                    building={building}
                    id={id}
                    chart={chart}
                    traitClasses={traitClasses}
                    updateChart={updateChart}
                  />
                ) : chart.type === "sankey" ? (
                  <SankeyChartEdit
                    building={building}
                    id={id}
                    chart={chart}
                    traitClasses={traitClasses}
                    updateChart={updateChart}
                  />
                ) : (
                  <ChartEdit
                    building={building}
                    id={id}
                    chart={chart}
                    traitClasses={traitClasses}
                    updateChart={updateChart}
                  />
                )}
              </Stack>
            </CardContent>
          </Card>
        </Container>
        {isValid && (
          <Container maxWidth="xl" sx={{ marginTop: 2 }}>
            <Chart
              chart={chart}
              startTime={DateTime.now().startOf("day")}
              endTime={DateTime.now().endOf("day")}
              hideEditButton={true}
            />
          </Container>
        )}
        {id && (
          <DeleteFab
            confirmText={
              chart.name
                ? t('Do you really want to delete chart "{{name}}"?', {
                    name: chart.name,
                  })
                : t("Do you really want to delete chart?")
            }
            onDelete={() => {
              chartUIStore.deleteChart(chart);
              window.history.back();
            }}
          />
        )}
      </ErrorScreen>
    </Box>
  );
};

export default ChartEditScreen;
