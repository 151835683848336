import { DropResult } from "@hello-pangea/dnd";
import EditIcon from "@mui/icons-material/Edit";
import { Button } from "@mui/material";
import { observer } from "mobx-react-lite";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "wouter";
import ITrait from "../services/Trait";
import traitStore from "../stores/TraitStore";
import roomUIStore from "../stores/UI/RoomUIStore";
import TraitCard from "./TraitCard";
import { ITraitChipHide } from "./TraitChip";

export interface IRoomUI {
  building_id: string;
  uuid: string;
  name: string;
  traits?: ITrait[];
  hideEditButton?: boolean;
}

const Room: FC<IRoomUI> = observer((room: IRoomUI) => {
  const { t } = useTranslation();
  const [, setLocation] = useLocation();
  const { hiddenTraits, traitOrder } = roomUIStore.find(room.uuid);
  const traits =
    room.traits === undefined
      ? traitStore.filter({ room_id: room.uuid, disabled: false })
      : room.traits;
  // https://stackoverflow.com/a/14438954
  const traitClasses = traits
    .map((trait) => trait.trait_class)
    .filter((trait_class, index, self) => self.indexOf(trait_class) === index)
    .sort((a, b) => (traitOrder.indexOf(a) < traitOrder.indexOf(b) ? -1 : 1));
  const traitGroups = traitClasses.map((traitClass) => ({
    id: traitClass,
    traits: traits.filter((trait) => trait.trait_class === traitClass),
  }));

  const onHide = (instance: ITraitChipHide) =>
    roomUIStore.hideTrait(room.uuid, instance.traitClass as string);
  const onShow = (instance: ITraitChipHide) =>
    roomUIStore.showTrait(room.uuid, instance.traitClass as string);

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    const newOrder = [...traitClasses];
    const [moved] = newOrder.splice(result.source.index, 1);
    newOrder.splice(result.destination.index, 0, moved);
    roomUIStore.setTraitOrder(room.uuid, newOrder);
  };

  return (
    <TraitCard
      id={
        room.hideEditButton ? `editable-room-${room.uuid}` : `room-${room.uuid}`
      }
      title={room.name}
      traitGroups={traitGroups}
      hiddenTraits={hiddenTraits}
      onHide={onHide}
      onShow={onShow}
      onDragEnd={onDragEnd}
      actions={
        room.hideEditButton
          ? undefined
          : [
              <Button
                key={`edit-${room.uuid}`}
                variant="contained"
                color="secondary"
                startIcon={<EditIcon />}
                onClick={() =>
                  setLocation(
                    `/building/${room.building_id}/room/${room.uuid}/`,
                  )
                }
              >
                {t("Edit")}
              </Button>,
            ]
      }
    />
  );
});

export default Room;
