import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import HomeIcon from "@mui/icons-material/Home";
import LogoutIcon from "@mui/icons-material/Logout";
import MenuIcon from "@mui/icons-material/Menu";
import SettingsIcon from "@mui/icons-material/Settings";
import {
  Box,
  Container,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  AppBar as MuiAppBar,
  SwipeableDrawer,
  Toolbar,
  Typography,
  useTheme,
} from "@mui/material";
import { observer } from "mobx-react-lite";
import { FC, ReactNode, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useRoute } from "wouter";
import buildingStore from "../stores/BuildingStore";
import { isSmall } from "../utils";
import Search from "./Search";
import StateDropDown from "./StateDropDown";

export interface IAppBarProps {
  title: string | undefined;
  withMenu?: boolean;
  withBack?: string | boolean;
  withState?: boolean;
  right?: ReactNode;
  setSearch?: (value: string) => void;
}

const AppBar: FC<IAppBarProps> = observer((props: IAppBarProps) => {
  const { t } = useTranslation();
  const { title, withMenu, withBack, withState, right } = props;
  const theme = useTheme();
  const smallDevice = isSmall();
  const [showTitle, setShowTitle] = useState(true);
  const [showDrawer, setShowDrawer] = useState(false);
  const [, setSearch] = useState<string>("");
  const [, buildingParams] = useRoute("/building/:uuid/:page?/:id?/");
  const [, setLocation] = useLocation();
  const buildings = buildingStore.instances
    .slice()
    .sort((a, b) => (a.name < b.name ? -1 : 1));
  const selectedBuildingUUID =
    buildingParams && buildingParams.uuid ? buildingParams.uuid : "";

  return (
    <>
      <MuiAppBar position="fixed" sx={{ zIndex: theme.zIndex.drawer + 1 }}>
        <Container maxWidth="xl" disableGutters>
          <Toolbar disableGutters sx={{ paddingLeft: 2, paddingRight: 2 }}>
            {withMenu && (
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={() => setShowDrawer(!showDrawer)}
              >
                <MenuIcon />
              </IconButton>
            )}
            {withBack && (
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="back"
                onClick={() => {
                  if (typeof withBack === "string") {
                    setLocation(withBack);
                  } else {
                    window.history.back();
                  }
                }}
              >
                <ArrowBackIcon />
              </IconButton>
            )}
            <Typography
              variant="h6"
              component="div"
              noWrap
              sx={{ flexGrow: 1, mr: 0 }}
            >
              {title && showTitle && title}
            </Typography>
            {props.setSearch && (
              <Search
                setSearch={(value: string) => {
                  setSearch(value);
                  props.setSearch && props.setSearch(value);
                }}
                onFocus={() => smallDevice && setShowTitle(false)}
                onBlur={(searchLength: number) =>
                  smallDevice && setShowTitle(searchLength === 0)
                }
              />
            )}
            {withState && <StateDropDown building_id={selectedBuildingUUID} />}
            {right && right}
          </Toolbar>
        </Container>
      </MuiAppBar>
      {withMenu && (
        <SwipeableDrawer
          anchor="left"
          open={showDrawer}
          onClose={() => setShowDrawer(false)}
          onOpen={() => setShowDrawer(true)}
        >
          <Toolbar />
          <Box
            sx={{
              minWidth: 200,
            }}
          >
            <List>
              {buildings.map((building) => (
                <ListItemButton
                  key={building.uuid}
                  selected={selectedBuildingUUID === building.uuid}
                  onClick={() => {
                    setLocation(`/building/${building.uuid}/`);
                    setShowDrawer(false);
                  }}
                >
                  <ListItemIcon>
                    <HomeIcon />
                  </ListItemIcon>
                  <ListItemText primary={building.name} />
                </ListItemButton>
              ))}
            </List>
            <List
              sx={{
                position: "absolute",
                left: 0,
                right: 0,
                bottom: 0,
                marginRight: 0,
              }}
            >
              <ListItemButton onClick={() => setLocation("/settings/")}>
                <ListItemIcon>
                  <SettingsIcon />
                </ListItemIcon>
                <ListItemText primary={t("Settings")} />
              </ListItemButton>
              <ListItemButton onClick={() => setLocation("/logout/")}>
                <ListItemIcon>
                  <LogoutIcon />
                </ListItemIcon>
                <ListItemText primary={t("Logout")} />
              </ListItemButton>
            </List>
          </Box>
        </SwipeableDrawer>
      )}
      <Toolbar />
    </>
  );
});

export default AppBar;
