import AcUnitIcon from "@mui/icons-material/AcUnit";
import AirIcon from "@mui/icons-material/Air";
import Battery30Icon from "@mui/icons-material/Battery30";
import Battery50Icon from "@mui/icons-material/Battery50";
import Battery80Icon from "@mui/icons-material/Battery80";
import BatteryFullIcon from "@mui/icons-material/BatteryFull";
import FlashOnIcon from "@mui/icons-material/FlashOn";
import InfoIcon from "@mui/icons-material/Info";
import LightModeIcon from "@mui/icons-material/LightMode";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import LightbulbOutlinedIcon from "@mui/icons-material/LightbulbOutlined";
import PowerIcon from "@mui/icons-material/Power";
import PowerOffIcon from "@mui/icons-material/PowerOff";
import RefreshIcon from "@mui/icons-material/Refresh";
import SensorsIcon from "@mui/icons-material/Sensors";
import SpeedIcon from "@mui/icons-material/Speed";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import WaterDropIcon from "@mui/icons-material/WaterDrop";
import { rgbToHex, useTheme } from "@mui/material";
import { observer } from "mobx-react-lite";
import { FC } from "react";
import ColorConverter from "../ColorConverter";
import BlindIcon from "./TraitIcons/BlindIcon";
import HumidityIcon from "./TraitIcons/HumidityIcon";

export interface ITraitIcon {
  traitClass: string;
  unit: string;
  value: number | string;
}

const TraitIcon: FC<ITraitIcon> = observer((props) => {
  const theme = useTheme();
  const { unit, value, traitClass } = props;
  let element = <span>{unit}</span>;

  let rgbValue = null;
  if (traitClass === "ColorXY") {
    const rgb = ColorConverter.xyBriToRgb(
      ...value
        .toString()
        .split(",")
        .map((x) => parseFloat(x)),
      255,
    );
    rgbValue = rgbToHex(
      `rgb(${rgb.r < 255 ? rgb.r : 255}, ${rgb.g < 255 ? rgb.g : 255}, ${
        rgb.b < 255 ? rgb.b : 255
      })`,
    );
  }

  switch (traitClass) {
    case "OnOff":
    case "PowerOnBehavior":
      element =
        typeof value === "number" && value > 0 ? (
          <PowerIcon />
        ) : (
          <PowerOffIcon />
        );
      break;
    case "Dimmer":
      element =
        typeof value === "number" && value > 0 ? (
          <LightbulbIcon />
        ) : (
          <LightbulbOutlinedIcon />
        );
      break;
    case "LinkQuality":
      element = <SensorsIcon />;
      break;
    case "Power":
      element = <FlashOnIcon />;
      break;
    case "Illuminance":
      element = <LightModeIcon />;
      break;
    case "Occupancy":
      element =
        typeof value === "number" && value > 0 ? (
          <VisibilityIcon />
        ) : (
          <VisibilityOffIcon />
        );
      break;
    case "Blind":
      element = <BlindIcon />;
      break;
    case "Humidity":
      element = <HumidityIcon />;
      break;
    case "BatteryLevel":
      if (typeof value === "number" && value < 30) {
        element = <Battery30Icon />;
      } else if (typeof value === "number" && value < 50) {
        element = <Battery50Icon />;
      } else if (typeof value === "number" && value < 90) {
        element = <Battery80Icon />;
      } else {
        element = <BatteryFullIcon />;
      }
      break;
    case "ACMode":
      element = <AcUnitIcon />;
      break;
    case "FanSpeed":
      element = <AirIcon />;
      break;
    case "Swing":
      element = <SwapVertIcon />;
      break;
    case "ColorXY":
      element = <LightbulbIcon style={{ color: `${rgbValue}` }} />;
      break;
    case "ToshibaMeritA":
      element = <InfoIcon />;
      break;
    case "TransferRate":
      element = <SpeedIcon />;
      break;
    case "UnifiPoEPowerCycle":
      element = <RefreshIcon />;
      break;
    case "WaterLeak":
      element = (
        <WaterDropIcon
          style={{
            color:
              typeof value === "number" && value === 0
                ? undefined
                : theme.palette.error.main,
          }}
        />
      );
      break;
    default:
      break;
  }
  return element;
});

export default TraitIcon;
