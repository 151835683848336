import { action, makeObservable } from "mobx";
import { computedFn } from "mobx-utils";
import ITrait, { setTraitValue } from "../services/Trait";
import BuildingChildPagedStore, {
  IBuildingChildFilter,
} from "./BuildingChildPagedStore";
import deviceStore from "./DeviceStore";
import roomStore from "./RoomStore";

export interface ITraitFilter extends IBuildingChildFilter {
  room_id?: string | null;
  gateway_id?: string;
  device_id?: string;
  trait_class?: string;
  channel?: string | null;
  disabled?: boolean;
  controllable?: boolean;
}

export type TSimpleTrait = Pick<
  ITrait,
  "trait_class" | "uuid" | "name" | "unit"
>;

class TraitStore extends BuildingChildPagedStore<ITrait, ITraitFilter> {
  constructor() {
    super("trait");
    this.persist();

    makeObservable(this, {
      set: action,
    });
  }

  set = async (uuid: string, value: number | string) => {
    const instance = this.find(uuid);
    if (!instance) {
      return;
    }
    setTraitValue(uuid, value);
  };

  sortedTraits = computedFn((filters: ITraitFilter) => {
    return this.filter(filters).sort((a, b) =>
      this.getTraitText(a.uuid) < this.getTraitText(b.uuid) ? -1 : 1,
    );
  });

  sortedSimpleTraits = computedFn((filters: ITraitFilter): TSimpleTrait[] => {
    return this.sortedTraits(filters).map((trait) => ({
      uuid: trait.uuid,
      trait_class: trait.trait_class,
      name: trait.name,
      unit: trait.unit,
    }));
  });

  getTraitText = computedFn((uuid: string) => {
    const trait = this.find(uuid);
    if (!trait) {
      return "";
    }
    if (trait.nickname) {
      return trait.nickname.trim();
    }
    const device = deviceStore.find(trait.device_id);
    const room = roomStore.find(device?.room_id || "");
    return `${room ? `(${room.name})` : ""} ${device?.name} ${
      trait.channel ? `(${trait.channel})` : ""
    }`.trim();
  });
}

const traitStore = new TraitStore();

export default traitStore;
