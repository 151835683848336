import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { observer } from "mobx-react-lite";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  IActionTrigger,
  IStateTrigger,
  ITimeTrigger,
  ITraitValueTrigger,
} from "../services/Rule";
import { IActionTrait } from "../services/Trait";
import stateStore from "../stores/StateStore";
import traitStore from "../stores/TraitStore";
import TranslatedTrait, { translateTrait } from "./TranslatedTrait";

export interface ITriggerCard {
  building: string;
  trigger: IStateTrigger | ITraitValueTrigger | ITimeTrigger | IActionTrigger;
  onUpdate: (
    trigger: IStateTrigger | ITraitValueTrigger | ITimeTrigger | IActionTrigger,
  ) => void;
  remove: () => void;
}

const StateTriggerContent: FC<ITriggerCard> = observer(
  (props: ITriggerCard) => {
    const { t } = useTranslation();
    const { building, trigger, onUpdate } = props;
    const { state: selectedStateUUID } = trigger as IStateTrigger;
    const states = stateStore.filter({ building_id: building });
    const selectedState = stateStore.find(selectedStateUUID) || states[0];

    return (
      <>
        <FormControl fullWidth>
          <InputLabel>{t("State")}</InputLabel>
          <Select
            variant="outlined"
            label={t("State")}
            value={selectedStateUUID || selectedState.uuid}
            renderValue={() => selectedState?.name || ""}
            onChange={(event) => {
              onUpdate({
                ...trigger,
                state: event.target.value,
              } as IStateTrigger);
            }}
          >
            {states.map((state) => (
              <MenuItem key={state.uuid} value={state.uuid}>
                {state.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </>
    );
  },
);

const TraitValueTriggerContent: FC<ITriggerCard> = observer(
  (props: ITriggerCard) => {
    const { t } = useTranslation();
    const { building, trigger, onUpdate } = props;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { type, value } = trigger as ITraitValueTrigger;
    const [selectedTraits, setSelectedTraits] = useState<string[]>(
      (trigger as ITraitValueTrigger).traits,
    );
    const traits = traitStore.sortedSimpleTraits({ building_id: building });
    const traitClasses = traits
      .map((x) => x.trait_class)
      .filter((trait_class, index, self) => self.indexOf(trait_class) === index)
      .sort((a, b) =>
        translateTrait(a).toLowerCase() < translateTrait(b).toLowerCase()
          ? -1
          : 1,
      );
    const [traitClass, setTraitClass] = useState<string>(
      (selectedTraits.length > 0 &&
        traitStore.find(selectedTraits[0])?.trait_class) ||
        traitClasses[0],
    );
    const [tempValue, setTempValue] = useState(value);

    return (
      <>
        <FormControl fullWidth>
          <InputLabel>{t("Class")}</InputLabel>
          <Select
            variant="outlined"
            label={t("Class")}
            value={traitClass}
            renderValue={(x) => <TranslatedTrait traitClass={x} />}
            onChange={(event) => {
              if (event.target.value !== traitClass) {
                setSelectedTraits([]);
              }
              setTraitClass(event.target.value);
            }}
          >
            {traitClasses.map((traitclass) => (
              <MenuItem key={traitclass} value={traitclass}>
                <TranslatedTrait traitClass={traitclass} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl fullWidth>
          <InputLabel>{t("Devices")}</InputLabel>
          <Select
            label={t("Devices")}
            multiple
            value={selectedTraits}
            renderValue={(selected) =>
              selected.map((item) => traitStore.getTraitText(item)).join(", ")
            }
            onChange={(event) => {
              const selected =
                typeof event.target.value === "string"
                  ? (event.target.value as string).split(",")
                  : event.target.value;
              setSelectedTraits(selected);
            }}
            onClose={() => {
              onUpdate({
                ...trigger,
                traits: selectedTraits,
              } as ITraitValueTrigger);
            }}
          >
            {traits
              .filter((trait) => trait.trait_class === traitClass)
              .map((trait) => (
                <MenuItem key={trait.uuid} value={trait.uuid}>
                  <Checkbox
                    color="secondary"
                    checked={selectedTraits.indexOf(trait.uuid) > -1}
                  />
                  {traitStore.getTraitText(trait.uuid)}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <FormControl fullWidth>
          <InputLabel>{t("Type")}</InputLabel>
          <Select
            variant="outlined"
            label={t("Type")}
            value={type}
            renderValue={(x) => (x === 0 ? t("value under") : t("value over"))}
            onChange={(event) => {
              onUpdate({
                ...trigger,
                type: parseInt(event.target.value.toString(), 10) === 1 ? 1 : 0,
              } as ITraitValueTrigger);
            }}
          >
            <MenuItem value={0}>{t("value under")}</MenuItem>
            <MenuItem value={1}>{t("value over")}</MenuItem>
          </Select>
        </FormControl>
        <TextField
          variant="outlined"
          type="number"
          label={t("Value")}
          value={tempValue.toString()}
          onChange={(event) => {
            setTempValue(parseFloat(event.target.value));
          }}
          onBlur={() => {
            if (Number.isFinite(tempValue)) {
              onUpdate({
                ...trigger,
                value: tempValue,
              } as ITraitValueTrigger);
            }
          }}
        />
      </>
    );
  },
);

interface ITimeSelector {
  label: string;
  values: string;
  // defaultValue?: string;
  choices: string[];
  // eslint-disable-next-line react/require-default-props
  choiceTitles?: { [key: string]: string };
  onClose: (value: string) => void;
}

const TimeSelector: FC<ITimeSelector> = observer((props: ITimeSelector) => {
  const { t } = useTranslation();
  const { label, values, choices, choiceTitles, onClose } = props;
  const [selectedValues, setSelectedValues] = useState<string[]>(
    values.split(",").filter((x) => x.length > 0),
  );

  return (
    <FormControl fullWidth>
      <InputLabel>{label}</InputLabel>
      <Select
        label={label}
        multiple
        value={selectedValues}
        renderValue={(selected) => {
          if (choiceTitles) {
            return selected.map((x) => choiceTitles[x]).join(", ");
          }
          return selected.join(", ");
        }}
        onChange={(event) => {
          let selected =
            typeof event.target.value === "string"
              ? (event.target.value as string).split(",")
              : event.target.value;
          if (selected.indexOf("all") !== -1) {
            selected = choices;
          } else if (selected.indexOf("clear") !== -1) {
            selected = [];
          }
          selected.sort((a, b) => (parseInt(a, 10) < parseInt(b, 10) ? -1 : 1));
          setSelectedValues(selected);
        }}
        onClose={() => {
          onClose(selectedValues.join(","));
        }}
      >
        <MenuItem value="all">
          <span style={{ fontWeight: "bold" }}>{t("Select all")}</span>
        </MenuItem>
        <MenuItem value="clear">
          <span style={{ fontWeight: "bold" }}>{t("Clear selections")}</span>
        </MenuItem>
        {choices.map((x) => (
          <MenuItem key={`choice-${x}`} value={x}>
            <Checkbox
              color="secondary"
              checked={selectedValues.indexOf(x) > -1}
            />
            {choiceTitles ? choiceTitles[x] : x}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
});

const TimeTriggerContent: FC<ITriggerCard> = observer((props: ITriggerCard) => {
  const { t } = useTranslation();
  const { trigger, onUpdate } = props;

  return (
    <>
      <TimeSelector
        label={t("Minutes")}
        values={(trigger as ITimeTrigger).minute}
        choices={Array.from({ length: 60 }, (x, i) => i.toString())}
        onClose={(value) => {
          onUpdate({
            ...trigger,
            minute: value,
          } as ITimeTrigger);
        }}
      />
      <TimeSelector
        label={t("Hours")}
        values={(trigger as ITimeTrigger).hour}
        choices={Array.from({ length: 24 }, (x, i) => i.toString())}
        onClose={(value) => {
          onUpdate({
            ...trigger,
            hour: value,
          } as ITimeTrigger);
        }}
      />
      <TimeSelector
        label={t("Months")}
        values={(trigger as ITimeTrigger).month}
        choices={Array.from({ length: 12 }, (x, i) => (i + 1).toString())}
        choiceTitles={{
          "1": t("January"),
          "2": t("February"),
          "3": t("March"),
          "4": t("April"),
          "5": t("May"),
          "6": t("June"),
          "7": t("July"),
          "8": t("August"),
          "9": t("September"),
          "10": t("October"),
          "11": t("November"),
          "12": t("December"),
        }}
        onClose={(value) => {
          onUpdate({
            ...trigger,
            month: value,
          } as ITimeTrigger);
        }}
      />
      <TimeSelector
        label={t("Days of month")}
        values={(trigger as ITimeTrigger).day_of_month}
        choices={Array.from({ length: 31 }, (x, i) => (i + 1).toString())}
        onClose={(value) => {
          onUpdate({
            ...trigger,
            day_of_month: value,
          } as ITimeTrigger);
        }}
      />
      <TimeSelector
        label={t("Days of week")}
        values={(trigger as ITimeTrigger).day_of_week}
        choices={Array.from({ length: 7 }, (x, i) => i.toString())}
        choiceTitles={{
          "0": t("Sunday"),
          "1": t("Monday"),
          "2": t("Tuesday"),
          "3": t("Wednesday"),
          "4": t("Thursday"),
          "5": t("Friday"),
          "6": t("Saturday"),
        }}
        onClose={(value) => {
          onUpdate({
            ...trigger,
            day_of_week: value,
          } as ITimeTrigger);
        }}
      />
    </>
  );
});

const ActionTriggerContent: FC<ITriggerCard> = observer(
  (props: ITriggerCard) => {
    const { t } = useTranslation();
    const { building, trigger, onUpdate } = props;
    const [selectedTraits, setSelectedTraits] = useState<string[]>(
      (trigger as IActionTrigger).traits,
    );
    const traits = traitStore.filter({
      building_id: building,
      trait_class: "Action",
    });
    const traitClass = "Action";
    const availableActions: string[] = traits
      .filter((trait) => selectedTraits.indexOf(trait.uuid) !== -1)
      .map((trait) => (trait as IActionTrait).actions.split(","))
      .reduce((a, b) => [...a, ...b], [])
      .filter(
        (value, index, self) =>
          value.length > 0 && self.indexOf(value) === index,
      );
    const [selectedActions, setSelectedActions] = useState<string[]>(
      (trigger as IActionTrigger).actions
        .trim()
        .split(",")
        .filter((x) => x !== "" && availableActions.indexOf(x) !== -1),
    );

    return (
      <>
        <FormControl fullWidth>
          <InputLabel>{t("Devices")}</InputLabel>
          <Select
            label={t("Devices")}
            multiple
            value={selectedTraits}
            renderValue={(selected) =>
              selected.map((item) => traitStore.getTraitText(item)).join(", ")
            }
            onChange={(event) => {
              const selected =
                typeof event.target.value === "string"
                  ? (event.target.value as string).split(",")
                  : event.target.value;
              setSelectedTraits(selected);
            }}
            onClose={() => {
              onUpdate({
                ...trigger,
                traits: selectedTraits,
              } as IActionTrigger);
            }}
          >
            {traits
              .filter((trait) => trait.trait_class === traitClass)
              .map((trait) => (
                <MenuItem key={trait.uuid} value={trait.uuid}>
                  <Checkbox
                    color="secondary"
                    checked={selectedTraits.indexOf(trait.uuid) > -1}
                  />
                  {traitStore.getTraitText(trait.uuid)}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <FormControl fullWidth>
          <InputLabel>{t("Actions")}</InputLabel>
          <Select
            label={t("Actions")}
            multiple
            value={selectedActions}
            renderValue={(selected) => selected.join(", ")}
            onChange={(event) => {
              const selected =
                typeof event.target.value === "string"
                  ? (event.target.value as string).split(",")
                  : event.target.value;
              setSelectedActions(selected);
            }}
            onClose={() => {
              onUpdate({
                ...trigger,
                actions: selectedActions.join(",").trim(),
              } as IActionTrigger);
            }}
          >
            {availableActions.map((action) => (
              <MenuItem key={action} value={action}>
                <Checkbox
                  color="secondary"
                  checked={selectedActions.indexOf(action) > -1}
                />
                {action}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </>
    );
  },
);

const TriggerCard: FC<ITriggerCard> = observer((props: ITriggerCard) => {
  const { t } = useTranslation();
  const { trigger, onUpdate, remove } = props;
  const [triggerClass, setTriggerClass] = useState<
    "StateTrigger" | "TraitValueTrigger" | "TimeTrigger" | "ActionTrigger"
  >(trigger.trigger_class);

  return (
    <Card>
      <CardContent>
        <Stack spacing={2} marginBottom={-1}>
          <FormControl fullWidth>
            <InputLabel>{t("Trigger class")}</InputLabel>
            <Select
              variant="outlined"
              label={t("Trigger class")}
              value={triggerClass}
              onChange={(event) => {
                const x = event.target.value;
                if (x === "StateTrigger") {
                  setTriggerClass(x);
                  onUpdate({
                    trigger_class: x,
                    state: "",
                  });
                } else if (x === "TimeTrigger") {
                  setTriggerClass(x);
                  onUpdate({
                    trigger_class: x,
                    minute: "",
                    hour: "",
                    month: "",
                    day_of_month: "",
                    day_of_week: "",
                  });
                } else if (x === "TraitValueTrigger") {
                  setTriggerClass(x);
                  onUpdate({
                    trigger_class: x,
                    traits: [],
                    type: 0,
                    value: 0.0,
                  });
                } else if (x === "ActionTrigger") {
                  setTriggerClass(x);
                  onUpdate({
                    trigger_class: x,
                    traits: [],
                    actions: "",
                  });
                }
              }}
            >
              <MenuItem value="StateTrigger">{t("StateTrigger")}</MenuItem>
              <MenuItem value="TraitValueTrigger">
                {t("TraitValueTrigger")}
              </MenuItem>
              <MenuItem value="TimeTrigger">{t("TimeTrigger")}</MenuItem>
              <MenuItem value="ActionTrigger">{t("ActionTrigger")}</MenuItem>
            </Select>
          </FormControl>
          {triggerClass === "StateTrigger" && (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <StateTriggerContent {...props} />
          )}
          {triggerClass === "TraitValueTrigger" && (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <TraitValueTriggerContent {...props} />
          )}
          {triggerClass === "TimeTrigger" && (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <TimeTriggerContent {...props} />
          )}
          {triggerClass === "ActionTrigger" && (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <ActionTriggerContent {...props} />
          )}
        </Stack>
      </CardContent>
      <CardActions>
        <Button
          variant="contained"
          color="secondary"
          startIcon={<DeleteForeverIcon />}
          onClick={() => remove()}
        >
          {t("Delete")}
        </Button>
      </CardActions>
    </Card>
  );
});

export default TriggerCard;
