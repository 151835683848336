import IGroup from "../services/Group";
import BuildingChildPagedStore, {
  IBuildingChildFilter,
} from "./BuildingChildPagedStore";

export type IGroupFilter = IBuildingChildFilter;

class GroupStore extends BuildingChildPagedStore<IGroup, IGroupFilter> {
  constructor() {
    super("group");
    this.persist();
  }
}

const groupStore = new GroupStore();
export default groupStore;
